import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const ARTICLE_CARD_FRAGMENT = `
  fragment articleCardFragment on Article {
    id
    uid
    schemaCode
    name
    subtitle
    authorName
    publicationTime
    updatedTime
    featured
    images {
      ...fileResourceBaseFragment
    }
    _isRecommendedForMe
    _isBookmarked(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
